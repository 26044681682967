<template>
    <div class="row" style="padding: 10px; background-color: purple">
        <div>
            <table class="table table-sm" style="font-size: 75%; color: white; table-layout: fixed">
                <thead class="thead2">
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody  v-for="service in Services" :key="service.key" class="borderBottom">
                    <tr v-if="service.key == 'Month'" style="width: 100%; background-color: black">
                        <td>{{service.name}}</td>
                    </tr>
                    <tr v-if="service.key !== 'Month'" :class="new Date(service.date).toLocaleString([], {weekday: 'long'})">
                        <td class="td2" rowspan=4 colspan=1 align="right" style="vertical-align: top; text-align: left">
                            <b>{{ new Date(service.date).toLocaleString([], {weekday: 'long'}) }} <br>
                        {{ new Date(service.date).toLocaleString([], {year: 'numeric', month: 'short', day: 'numeric'}) }}<br>
                        {{ new Date(service.date).toLocaleString([], {hour: '2-digit', minute: '2-digit'}) }}</b><br>
                        <h5 style="text-align: right; float: right"><b>{{ service.bl }}</b></h5>
                        <h6 style="font-size: 125%; float: left; text-align: right" v-if="service.cremation == true"><b>Crem.</b></h6>
                        <h7 style="text-align: left; float: left"><b>{{ service.casket }}</b></h7>
                        </td>
                    </tr>
                    <tr v-if="service.key !== 'Month'" class="borderSmall">
                        <td class="td2" style="font-size: 150%"><span v-if="service.covid == true" class="covid"><b>{{ service.name }}</b></span>
                        <span v-if="service.covid != true"><b>{{ service.name }}</b></span></td>
                        <td class="td2" colspan=2><span v-if="service.notes != null && service.notes != ''"><b>Service Notes:</b> {{ service.notes }}</span>
                        <span v-if="service.notes == null || service.notes == ''"><b>Service Notes: </b>None</span></td>
                        <td colspan=2 class="td2"><span v-if="service.visitationstart != null && service.visitationstart != ''"><b>Visitation Starts: </b>{{new Date(service.visitationstart).toLocaleString([], {weekday: 'short'})}} {{new Date(service.visitationstart).toLocaleString([], {year: 'numeric', month: 'short', day: 'numeric'})}} at {{new Date(service.visitationstart).toLocaleString([], {hour: '2-digit', minute: '2-digit'})}}<br><b>Ends: </b>{{new Date("February 04, 2011 "+service.visitationend).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}}</span>
                        <span v-if="service.visitationstart == null || service.visitationstart == ''"><b>Visitation: </b>None</span></td>
                    </tr>
                    <tr v-if="service.key !== 'Month'" class="borderSmall">
                        <td class="td2">{{service.serviceLoc}}</td>
                        <td class="td2" v-html="service.sr" colspan=2></td> 
                        <td class="td2" colspan=2><span v-if="service.music != null && service.music != ''"><b>Music Notes:</b> {{ service.music }}</span>
                        <span v-if="service.music == null || service.music == ''"><b>Music Notes: </b>None</span></td>
                    </tr>
                    <tr v-if="service.key !== 'Month'" class="borderBottom">
                        <td class="td2"><span style="white-space: nowrap">{{ service.cem }} <svg v-if="service.contacted == true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></span><br>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span style="white-space: nowrap">{{service.container}} <svg v-if="service.containerDone == true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></span></td>
                        <td class="td2" colspan=2 v-html="service.pers"></td>
                        <td class="td2" v-html="service.veh"></td>
                        <td class="td2" style=""><span v-if="service.familyin != null && service.familyin != ''"><b>Family In: </b><span style="white-space: nowrap">{{new Date(service.familyin).toLocaleString([], {weekday: 'short'})}} {{new Date(service.familyin).toLocaleString([], {year: 'numeric', month: 'short', day: 'numeric'})}} </span><span style="white-space: nowrap">at {{new Date(service.familyin).toLocaleString([], {hour: '2-digit', minute: '2-digit'})}}</span></span></td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-sm" style="font-size: 75%; color: white">
                <thead class="thead2">
                    <tr>
                        <th>Pending Services</th>
                    </tr>
                </thead>
                <tbody  v-for="service in FullServices" :key="service.key" class="borderBottom">
                    <tr class="borderBottom pending">
                        <td class="td2" rowspan=4 colspan=1>
                        <h6 style="float: left; float: top"><b><span v-if="service.date == 'TBD'">Date </span>{{ service.date }}</b></h6><br>
                        <h6 style="float: left; float: top"><span v-if="service.dod != ''"><b>DOD:</b> {{ new Date(service.dod).toLocaleString([], {weekday: 'long'}) }}
                        {{ new Date(service.dod).toLocaleString([], {year: 'numeric', month: 'short', day: 'numeric'}) }} at
                        {{ new Date(service.dod).toLocaleString([], {hour: '2-digit', minute: '2-digit'}) }}</span></h6>
                        <br>
                        <h6 style="float: left; float: bottom"><span v-if="service.familyin != null && service.familyin != ''"><b>Family In: </b><span style="white-space: nowrap">{{new Date(service.familyin).toLocaleString([], {weekday: 'short'})}} {{new Date(service.familyin).toLocaleString([], {year: 'numeric', month: 'short', day: 'numeric'})}} at {{new Date(service.familyin).toLocaleString([], {hour: '2-digit', minute: '2-digit'})}}</span></span></h6>
                        <h5 style="float: right"><b>{{ service.bl }}</b></h5></td>
                    </tr>
                    <tr class="borderBottom">
                        <td v-if="service.covid == true" class="covid td2" style="font-size: 150%"><b>{{ service.name }}</b></td>
                        <td class="td2" v-if="service.covid != true" style="font-size: 150%"><b>{{ service.name }}</b></td>
                        <td class="td2" colspan=2><span v-if="service.notes != null && service.notes != ''"><b>Service Notes:</b> {{ service.notes }} </span></td>
                    </tr>
                </tbody>
                </table>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Services: [],
                FullServices: [],
                timer: '',
                currentTenant: "",
                weekday: '',
                Months: {
                    January: 0,
                    February: 0,
                    March: 0,
                    April: 0,
                    May: 0,
                    June: 0,
                    July: 0,
                    August: 0,
                    September: 0,
                    October: 0,
                    November: 0,
                    December: 0,
                },
            }
        },
        
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
        },
        mounted: function () {
        window.setInterval(() => {
            this.autorefresh()
        }, 7200000)
        },
        methods: {
            updateservices() {
                db.collection('services').onSnapshot((snapshotChange) => {
                this.Services = [];
                this.FullServices = [];
                snapshotChange.forEach((doc) => {
                    if(this.currentTenant == doc.data().tenant) {
                    if(new Date(doc.data().date).getTime() >= new Date(new Date().setHours(0, 0, 0, 0)).getTime() && doc.data().pending !== true && doc.data().deleted !== true  && doc.data().archivedtime == null) {
                    if(new Date(doc.data().date).getMonth() == 0) {
                        this.Months.January = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 1) {
                        this.Months.February = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 2) {
                        this.Months.March = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 3) {
                        this.Months.April = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 4) {
                        this.Months.May = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 5) {
                        this.Months.June = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 6) {
                        this.Months.July = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 7) {
                        this.Months.August = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 8) {
                        this.Months.September = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 9) {
                        this.Months.October = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 10) {
                        this.Months.November = 1;
                    }
                    if(new Date(doc.data().date).getMonth() == 11) {
                        this.Months.December = 1;
                    }
                    this.Services.push({
                        key: doc.id,
                        name: doc.data().name,
                        notes: doc.data().notes,
                        date: doc.data().date,
                        serviceLoc: doc.data().serviceLoc,
                        cem: doc.data().cem,
                        veh: this.vehFormat(doc.data().veh, doc.data().limocount),
                        pers: this.persFormat(doc.data().pers, doc.data().dir),
                        bl: doc.data().bl,
                        sr: this.viewservicerequirements(doc.data().srs),
                        music: doc.data().music,
                        casket: doc.data().casket,
                        cemnotes: doc.data().cemnotes,
                        cremation: doc.data().cremation,
                        covid: doc.data().covid,
                        container: doc.data().container,
                        contacted: doc.data().cemContacted,
                        containerDone: doc.data().containerDone,
                        visitationstart: doc.data().visitationstart,
                        visitationend: doc.data().visitationend,
                        familyin: doc.data().familyin,
                    })
                    }
                    if(doc.data().pending == true && doc.data().deleted !== true) {
                    this.FullServices.push({
                        key: doc.id,
                        name: doc.data().name,
                        notes: doc.data().notes,
                        date: doc.data().date,
                        serviceLoc: doc.data().serviceLoc,
                        cem: doc.data().cem,
                        veh: this.vehFormat(doc.data().veh, doc.data().limocount),
                        pers: this.persFormat(doc.data().pers, doc.data().dir),
                        bl: doc.data().bl,
                        sr: this.viewservicerequirements(doc.data().srs),
                        music: doc.data().music,
                        casket: doc.data().casket,
                        cemnotes: doc.data().cemnotes,
                        cremation: doc.data().cremation,
                        covid: doc.data().covid,
                        container: doc.data().container,
                        contacted: doc.data().cemContacted,
                        containerDone: doc.data().containerDone,
                        visitationstart: doc.data().visitationstart,
                        visitationend: doc.data().visitationend,
                        familyin: doc.data().familyin,
                        dod: doc.data().dod,
                    })
                    }
                    }
                    this.Services = this.Services.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                    this.FullServices = this.FullServices.sort((a, b) => new Date(a.dod).getTime() - new Date(b.dod).getTime())
                });
                if(this.Months.January > 0) {
                this.Services.push({
                        key: 'Month',
                        name: 'January',
                        date: new Date(new Date().getFullYear(), 0, 1),
                    })
                }
                if(this.Months.February > 0) {
                    this.Services.push({
                        key: 'Month',
                        name: 'February',
                        date: new Date(new Date().getFullYear(), 1, 1),
                    })
                }
                if(this.Months.March > 0) {
                    this.Services.push({
                        key: 'Month',
                        name: 'March',
                        date: new Date(new Date().getFullYear(), 2, 1),
                    })
                }
                if(this.Months.April > 0) {
                this.Services.push({
                        key: 'Month',
                        name: 'April',
                        date: new Date(new Date().getFullYear(), 3, 1),
                    })
                }
                if(this.Months.May > 0) {
                    this.Services.push({
                        key: 'Month',
                        name: 'May',
                        date: new Date(new Date().getFullYear(), 4, 1),
                    })
                }
                if(this.Months.June > 0) {
                    this.Services.push({
                        key: 'Month',
                        name: 'June',
                        date: new Date(new Date().getFullYear(), 5, 1),
                    })
                }
                if(this.Months.July > 0) {
                this.Services.push({
                        key: 'Month',
                        name: 'July',
                        date: new Date(new Date().getFullYear(), 6, 1),
                    })
                }
                if(this.Months.August > 0) {
                    this.Services.push({
                        key: 'Month',
                        name: 'August',
                        date: new Date(new Date().getFullYear(), 7, 1),
                    })
                }
                if(this.Months.September > 0) {
                    this.Services.push({
                        key: 'Month',
                        name: 'September',
                        date: new Date(new Date().getFullYear(), 8, 1),
                    })
                }
                if(this.Months.October > 0) {
                this.Services.push({
                        key: 'Month',
                        name: 'October',
                        date: new Date(new Date().getFullYear(), 9, 1),
                    })
                }
                if(this.Months.November > 0) {
                    this.Services.push({
                        key: 'Month',
                        name: 'November',
                        date: new Date(new Date().getFullYear(), 10, 1),
                    })
                }
                if(this.Months.December > 0) {
                    this.Services.push({
                        key: 'Month',
                        name: 'December',
                        date: new Date(new Date().getFullYear(), 11, 1),
                    })
                }
                this.Services = this.Services.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            })
            },
            autorefresh() {
                this.$router.go();
            },
            vehFormat(vehs, lcount) {
                if(vehs != "None") {
                   let reqs = vehs.split(', ')
                   let formatted = ""
                   formatted += "<b>Vehicles: </b>"
                   if(vehs.includes('Limo')) {
                   formatted += "Limo("+lcount +"), "
                   }
                   for(let i = 0; i < reqs.length; i++) {
                       if(reqs[i].trim() != 'Limo') {
                           formatted += reqs[i]
                           if(i+1 < reqs.length) {
                            formatted += ", "
                           }
                       }
                       
                   }
                   return formatted
                }
                else {
                    return "<b>Vehicles: </b>None"
                }
            },
            persFormat(people, director) {
                if(people != "" && people != null) {
                   let reqs = people
                   //console.log("PERSSSSSSSS: ",reqs)
                   let formatted = ""
                   formatted += "<b>Personnel: </b>"
                   if(director != null && director != "") {
                       formatted += director+" (D)"
                       if(people.find(x => x.name === director) != null) {
                           if(people.find(x => x.name === director).setup == true) {
                               formatted += "(SU), "
                           } 
                           else {
                           formatted += ", "
                       }
                       }
                       else {
                           formatted += ", "
                       }
                   }
                   for(let i = 0; i < reqs.length; i++) {
                       if(reqs[i].name != director) {
                           formatted += '<span style="white-space: no-wrap">'+reqs[i].name
                            if(reqs[i].setup) {
                                formatted += ' (SU)'
                            }
                           if(i+1 < reqs.length) {
                            formatted += ", </span>"
                           }
                           else {
                               formatted += '</span>'
                           }
                       }
                   }
                   return formatted
                }
                else {
                    return "<b>Personnel: </b>None"
                }
            },
            viewservicerequirements(srs) {
               //console.log("AAAAAAAAAAAA", srs) 
               let reqs = srs
               let formatted = ""
               console.log(srs);
               if(srs.length !== 0) {
               formatted += "<b>Service Requirements: </b>"
               for(let i = 0; i < reqs.length; i++) {
                   if(reqs[i].done) {
                       formatted += '<span style="white-space: nowrap"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg>'
                       formatted += reqs[i].name
                   }
                   else {
                       formatted += '<span style="white-space: nowrap">'+reqs[i].name
                   }
                   if(i+1 < reqs.length) {
                            formatted += ","
                    }
                    formatted += '</span> '
               }
               }
               else {
                   formatted += "<b>Service Requirements: </b>None"
               }
               return formatted
            },
            deleteService(id){
              if (window.confirm("Do you really want to delete?")) {
                db.collection("services").doc(id).delete().then(() => {
                    console.log("Service deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    table{
    /*table-layout: fixed;*/
    }
    hr.solid {
  border-top: 3px solid #bbb;
}
    .pending {
        background-color: grey;
    }
    .Monday {
        background-color: red;
    }
    .Tuesday {
        background-color: orange;
    }
    .Wednesday {
        background-color: gold;
    }
    .Thursday {
        background-color: green;
    }
    .Friday {
        background-color: darkblue;
    }
    .Saturday {
        background-color: indigo;
    }
    .Sunday {
        background-color: deeppink;
    }
    .td2{
    word-wrap:break-word;
    text-shadow: 0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000;
    }
    .thead2{
    font-size: 150%;
    color: aqua;
    word-wrap:break-word;
    text-shadow: 0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000,
	0px 0px 1px #000;
    }
    .no-border td {
        border: 0px;
    }
    .borderBottom td {
        border-bottom: 4px solid white;
    }
    .borderSmall td {
        border-bottom: 1px solid gray;
    }
    .covid {
        color: red;
    }

</style>