<template>
    <Criswell v-if="currentTenant == 'criswellfh'"/>
    <Admin v-if="currentTenant == 'admin'"/>
    <Waldrop v-if="currentTenant == 'waldropfh'"/>
</template>

<script>
import Waldrop from '../components/TVDisplayWaldrop'
import Criswell from '../components/TVDisplayCriswell'
import Admin from '../components/TVDisplayAdmin'
    export default {
        components: {
               Criswell,
               Admin,
               Waldrop,
              },
        data() {
            return {
                currentTenant: "",
            }
        },
        
        created() {
            this.currentTenant = this.$tenant;
        },
        methods: {
           
        }
    }
</script>